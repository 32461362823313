export const linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage":
      return "/"
    case "faq":
      return "/faq"
    default:
      // Sensible fallback
      return "/"
  }
}
